import React, { useState, useEffect, useCallback, useMemo, Fragment } from "react";
// import { useDispatch } from "react-redux";
import HomeStyle from "../../../styles/sass/home.module.scss";

//import IntroVideo from "../../../../public/assets/home-video.mp4";
//import IntroPoster from "../../../../public/assets/intro-poster.jpg";
// import Bookonlinebtn from "../../utils/Bookonlinebtn";

/* import Bookonlinebtn from '../../utils/Bookonlinebtn'; */
//import Loading from '../../utils/Loading';
// import Sliderbanner from './Sliderbanner';

// import { getAllCMS } from "../../../redux/cms/CMSActions";

//const Sliderbanner = React.lazy(() => import("./Sliderbanner"));

import Locationpopup from "../../utils/Locationpopup";
import LearnMore from "../../utils/LearnMore";

import Sliderbanner from "./Sliderbanner";
import Aboutdesk from "../../utils/Aboutdesk";
import constants from "../../../utils/Constants";
import { useSelector } from "react-redux";
import Bookonlinebtn from "../../utils/Bookonlinebtn";
import {setHeaderSpace } from "../../../utils/CommonFunctions";

const {HOMEPAGE_FOLDS, CMS_TYPES, CMS_PAGES } = constants


const Home = ({ popup, openPopup, reinitSliderhome, showLandingPage }) => {
  //const dispatch = useDispatch();

  /* const [video, setVideo] = useState(false); */
  const [sliderShow, setSliderShow] = useState(false);
  //const [addClass, setAddClass] = useState(true);

  const [contentPopup, setcontentPopup] = useState(false);
  const [deskSpaceId, setDeskSpaceId] = useState(null);

  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showLocationPopup, setShowLocationPopup] = useState(false);

  const handleLocationPopup = useCallback((show) => {
    console.log(show);
    show = typeof show === "boolean" && show;
    setShowLocationPopup(show);
  }, []);

  const handleLearnMorePopup = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setShowLearnMore(show);
  }, []);

  const { cmsMap, boards } = useSelector((state) => state?.cms);
  
  const sliderPageId = useMemo(() => {
    const sliderBoardKey = JSON.stringify({
      type: CMS_TYPES.PAGE_META,
      page_name: CMS_PAGES.HOME,
    });
    return boards[sliderBoardKey]?.list?.[0];
  }, [boards]);

  useEffect(() => {
    setTimeout(() => {
      setSliderShow(true);
    }, 100);
  }, []);

    /* for set header */
  useEffect(() => {
    setHeaderSpace()
  }, []);

  const contentPopupEvent = useCallback((e, deskSpaceId) => {
    setcontentPopup(e);
    setDeskSpaceId(deskSpaceId);
  }, []);

  

  return (
    <>
      <Aboutdesk
        deskSpaceId={deskSpaceId}
        contentPopup={contentPopup}
        contentPopupEvent={setcontentPopup}
      />


      <Locationpopup
        mapImage={"/assets/images/map.jpg"}
        popupValue={showLocationPopup}
        closePopup={handleLocationPopup}
      />

      {/* <LearnMore
        pageId={sliderPageId}
        show={showLearnMore}
        handleDrawer={handleLearnMorePopup}
      /> */}




      {/* <Suspense fallback={<Loading />}>  poster={IntroPoster} */}
      <div className={HomeStyle.nw_overlay}>
        <div id="setSpacer" className="setBanner">
          <div className="bannerInner">
            <video
              id={`${HomeStyle.background_video}`}
              playsInline
              autoPlay
              loop
              muted
              poster={`./assets/intro-poster.jpg`}
              style={{ width: "100%", height: "100%" }}
            >
              <source src={`./assets/home-video.mp4`} type="video/mp4"></source>
              <track label="English" kind="captions" srcLang="en" src="" default />
              {/* <source src={IntroVideo} type="video/mp4"></source> */}
            </video>
            {/* <video id="background-video" autoPlay loop muted={video ? false : true} poster={IntroPoster}>
                        <source src={IntroVideo} type="video/mp4">
                        </source>
                    </video> */}

            {/* <div className={sliderShow ? "" : "hideElement"}> */}

            <Sliderbanner
              popupValue={popup}
              openPopup={openPopup}
              contentPopup={contentPopup}
              contentPopupEvent={contentPopupEvent}
              showLandingPage={showLandingPage}
              handleLearnMorePopup = {handleLearnMorePopup}
              handleLocationPopup = {handleLocationPopup}
            />

            <div className="btn_booking-online hide-mobile">
            <Bookonlinebtn />
            </div>

          </div>
        </div>
      </div>

      {/* section overview */}

      {HOMEPAGE_FOLDS.map(fold => {
        const {Component,_id} = fold
        const mCMS = cmsMap[_id]
        if(mCMS?.active)
          return <Component key={_id} data={mCMS} />
      })}


      {/* </div> */}
      {/*  </Suspense> */}

      {/* <Bookonlinebtn /> */}
      {/* <div className={`vol_icon ${video ? "unmute" : "mute"}`} onClick={() => volContainer()}>volume icon</div> */}
    </>
  );
};

export default Home;
