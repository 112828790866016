import React from "react";
import { useSelector } from "react-redux";

const Aboutdesk = (props) => {
  const { contentPopup, contentPopupEvent, deskSpaceId } = props;

  //console.log("deskSpaceId", deskSpaceId);

  const mCms = useSelector((state) => state.cms.cmsMap?.[deskSpaceId]);
  const knowMoreDetails = mCms?.meta?.knowMore || [];

  return (
    <>
      <div
        className={`c_b_overlay ${contentPopup ? "c_b_active" : ""}`}
        onClick={() => contentPopupEvent(false)}
      >
        <div className="c_b_inner" onClick={(e) => e.stopPropagation()}>
          <span className="c_b_close" onClick={() => contentPopupEvent(false)}>
            Close
          </span>
          <div className="c_b_i_cb">
            {/* <h3>Lorem ipsum sit</h3>
                        <p>Lorem ipsum dolor sit amet. Voluptatibus voluptas vel porro quia sed internos atque ex saepe molestias est cupiditate vitae in possimus quia ea provident porro. In cupiditate dolore At galisum minus et soluta tempora aut rerum dolore ut quam magnam sit perspiciatis architecto.</p>

                        <h4>Qui voluptas earum aut cupiditate sint qui consequatur!</h4>
                        <p>Non voluptas quia non quas placeat aut dicta sunt qui autem rerum et mollitia porro ut dolore debitis. Blanditiis quia vel blanditiis magni ea sunt rerum. Et corporis modi sed alias nihil ex voluptatum voluptas ad nulla dolores</p>

                        <h5>At voluptatem cumque id porro numquam.</h5>
                        <p>33 soluta illum sit temporibus maiores aut maiores rerum vel enim voluptatum aut voluptate sequi et nemo iusto. Qui odit corporis nam vitae dolorum aut esse voluptate qui Quis dolorum aut corrupti error est iure laboriosam At neque repellat.</p>

                        <p>Lorem ipsum dolor sit amet. Voluptatibus voluptas vel porro quia sed internos atque ex saepe molestias est cupiditate vitae in possimus quia ea provident porro. In cupiditate dolore At galisum minus et soluta tempora aut rerum dolore ut quam magnam sit perspiciatis architecto. Qui voluptas earum aut cupiditate sint qui consequatur consequatur!</p>

                        <p>Non voluptas quia non quas placeat aut dicta sunt qui autem rerum et mollitia porro ut dolore debitis. Blanditiis quia vel blanditiis magni ea sunt rerum. Et corporis modi sed alias nihil ex voluptatum voluptas ad nulla dolores At voluptatem cumque id porro numquam.</p>

                        <p>33 soluta illum sit temporibus maiores aut maiores rerum vel enim voluptatum aut voluptate sequi et nemo iusto. Qui odit corporis nam vitae dolorum aut esse voluptate qui Quis dolorum aut corrupti error est iure laboriosam At neque repellat 1.</p> */}

            {knowMoreDetails?.map?.((detail) => {
              const contentType = detail?.type?.toLowerCase?.();
              const content = detail?.value;

              if (contentType === "heading") {
                return <h3>{content}</h3>;
              } else if (contentType === "subheading") {
                return <h4>{content}</h4>;
              } else {
                return <p>{content}</p>;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutdesk;
